@import "../../Common.module.scss";
@import "../../variables";
@import "../../breakpoints";

.alertNimbusFreeBannerContainer {
    abbr {
        text-decoration: none;
    }
}

.alertPaymentBannerContainer {
    p {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    abbr {
        text-decoration: none;
    }
}
